.calendarContent {
	display: flex;
}

.calendarContent p {
	font-family: bold 100%;
	font-size: 11px;
	width: 100%;
	height: auto;

	margin: auto;
	white-space: wrap;
	overflow: hidden;
}

.circulo {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	margin: 4px;
	margin-top: 9px;
}
