.textLabel {
	font-size: 14px;
	color: var(--color-grey-main);
}

.textValue {
	font-size: 15px;
}

.tabsStyle {
	background: #ffffff !important;
	text-transform: none !important;
	min-height: 50px !important;
}

.grey {
	color: var(--color-grey-main);
}

.img {
	width: 100%;
	height: auto;
}

.imgContainer {
	display: flex;
	align-items: center;
}

.tabsStyle {
	background: #ffffff !important;
	border-top-left-radius: 8px !important;
	border-top-right-radius: 8px !important;
	text-transform: none !important;
	margin-right: 10px !important;
	min-height: 50px !important;
}
