.menuDrawer,
.submenuDrawer {
	display: block;
	width: 100%;
	list-style: none;
	margin: 0px;
	padding: 0px;
	user-select: none;
	transition: all 0.3s ease;
}

.menuDrawer > li > div {
	display: flex;
	align-items: center;
	padding-top: 7px;
	border-radius: 10px;
	font-size: 14px;
	color: #acacac;
}

.menuDrawer > li > div > svg:first-child {
	margin-right: 15px !important;
}

.menuDrawer > li > div[data-active='true'] {
	color: white;
}

.menuDrawer > li > div:hover {
	color: white;
}

.menuDrawer > li > div[data-active='true'] svg:first-child {
	color: #a50018 !important;
}

.menuDrawer > li > div:hover svg:first-child {
	color: #a50018 !important;
}

.menuDrawer > li > div > span {
	flex-grow: 1;
}

.menuDrawer > li,
.submenuDrawer > li {
	cursor: pointer;
}

.menuDrawer > li > ul > li {
	margin-left: 15px;
}

.menuDrawer > li > ul > li:hover {
	fill: white !important;
}

.menuDrawer > li > ul > li:hover span svg {
	fill: #a50018 !important;
}

.menuDrawer > li > ul > li[data-active='true'] {
	fill: white;
}

.menuDrawer > li > ul > li[data-active='true'] > span > svg {
	fill: #acacac;
}
.menuDrawer > li > ul > li[data-active='true']:hover > span > svg {
	fill: #a50018 !important;
}

.submenuDrawer {
	height: 0px;
	overflow: hidden !important;
	margin: 10px 0px 10px 0px;
	padding: 0px;
	font-size: 16px;
	transform: scaleY(0);
	transform-origin: top;
	transition: transform 0.3s ease;
	max-width: 300px;
	width: 100%;
}

.submenuDrawerResponsive {
	height: 0px;
	overflow: hidden !important;
	margin: 10px 0px 10px 0px;
	padding: 0px;
	font-size: 16px;
	transform: scaleY(0);
	transform-origin: top;
	transition: transform 0.26s ease;
	max-width: 150px;
	width: 100%;
}

.submenuDrawer > li {
	fill: #acacac;
	padding: 7px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.submenuDrawer > li > span {
	padding-top: 10px;
	padding-bottom: 10px;
}

.submenuDrawer[data-active='true'] {
	height: auto;
	transform: scaleY(1);
}

.menuDrawer > li {
	margin: 20px 0px 20px 0px;
}

.arrowMaximus {
	position: absolute;
	right: -25px;
	top: 45px;
	width: 40px;
	height: 40px;
	background: #ffffff;
	color: gray;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	z-index: 1300;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
		rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	border: 1px solid rgba(50, 50, 93, 0.25);
	cursor: pointer;
}

.menuDrawer[data-small='true'] > li > div {
	padding: 10px 15px;
}

.menuDrawer[data-small='true'] > li > div > span,
.menuDrawer[data-small='true'] > li > ul {
	display: none;
}
