.iconSchedule {
	display: flex;
	width: 44px;
	background: rgb(0, 128, 249);
	height: 44px;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
}

.titleSchedule {
	color: #0080f9;
	font-size: 23px;
	margin-left: 20px;
	font-weight: bold;
}

.containerHeader {
	display: flex;
	align-items: center;
}
