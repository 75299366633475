.buttonIconSmall {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px 5px;
	min-width: 60px;
	width: 80px;
	height: 68px;
	border-radius: 5px;
	font-size: 12px;
	font-weight: bold;
	flex-direction: column;
	cursor: pointer;
	border-width: 1px;
	border-style: solid;
	text-align: center;
}
.buttonIconSmall:active {
	opacity: 0.7;
}
