.title {
	font-size: 12px;
}

.dateSelected {
	font-size: 30px;
	font-size: 30px;
	font-weight: bold;
}

.dateSelectedContainer {
	display: flex;
	align-items: center;
	gap: 30px;
	cursor: pointer;
}

.icon {
	font-size: 1.2em !important;
}
