.backgroundGrey {
	background-color: #f8f8f8 !important;
	height: 100% !important;
}

.title {
	font-weight: 700;
	font-size: 16px;
	display: flex;
	align-items: center;
}

.blue {
	color: #0080f9;
	z-index: 0;
	cursor: pointer;
}

.infoTaskContainer {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.grey {
	color: var(--color-grey-main);
}

.listTask {
	display: flex;
	gap: 8px;
	align-items: center;
}

.circleTask {
	font-size: 10px !important;
}
