.iconSuccess {
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 80px;
	text-align: center;
}

.iconSuccess svg {
	color: #3bc33b;
	width: 100px;
	height: 100px;
}

.buttonSuccess {
	height: 150px;
	color: #0080f9;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 35px;
}

.buttonSuccess p {
	text-decoration: underline;
	cursor: pointer;
}
