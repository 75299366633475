.containerVerticalStepper {
	flex-wrap: nowrap;
	align-items: flex-start;
}

.verticalStepperSxElement {
	position: sticky !important;
	left: -5px;
	z-index: 1;
	height: 100%;
	background: #fff;
	min-width: 5rem;
}

.titleUserManagement {
	color: #0080f9;
	font-size: 23px;
	margin-left: 20px;
	font-weight: bold;
}
