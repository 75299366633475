.alert {
	box-sizing: border-box;
	margin-top: 10px;
	margin-bottom: 10px;
	border-radius: 6px;
	font-weight: 1000 !important;
	text-align: left;
}

.sizeIcon {
	width: 22px;
	height: 22px;
}
