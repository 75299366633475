.avatar {
	width: 100px !important;
	height: 100px !important;
	font-size: 40px !important;
	font-weight: 700 !important;
	color: var(--color-primary-main) !important;
	background-color: var(--color-error-light) !important;
	box-shadow: 9px 10px 29px -13px rgba(0, 0, 0, 0.37) !important;
}

.moreIcon,
.editIcon {
	background-color: #ebf8f8 !important;
	border-radius: 10px !important;
	box-shadow: 9px 10px 29px -13px rgba(0, 0, 0, 0.37) !important;
}

.editIcon {
	background-color: #0080f9 !important;
	color: white !important;
	margin-left: 20px !important;
}

.textLabel {
	font-size: 14px;
	color: var(--color-grey-main);
}

.textValue {
	font-size: 15px;
}

.tabsStyle {
	background: #ffffff !important;
	text-transform: none !important;
	margin-right: 10px !important;
	min-height: 50px !important;
}

.grey {
	color: var(--color-grey-main);
}

.containerStatus {
	display: flex;
	align-items: center;
	gap: 10px;
}

.circleStyle {
	height: 10px;
	width: 10px;
	border-radius: 50%;
}

.deleteIcon {
	margin-left: 10px !important;
	color: var(--color-error-main) !important;
	background-color: var(--color-error-light) !important;
	border-radius: 10px !important;
	box-shadow: 9px 10px 29px -13px rgba(0, 0, 0, 0.37) !important;
}
