.icon {
	font-size: 5vh !important;
	padding: 6px;
	color: white;
	background-color: #744cb4;
	border-radius: 50%;
}

.title {
	font-size: 18px;
	margin: 0;
}

.subtitle {
	font-size: 15px;
	color: #9d9dac;
	font-weight: 600;
}

.subtitleProject {
	font-size: 15px;
	color: #6c6c6c;
	font-weight: 600;
}

.stats {
	color: #6c6c6c;
	font-size: 30px;
	font-weight: 600;
}

.hours {
	font-size: 20px;
}

.cardContainer {
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
	height: 100%;
	width: 100%;
}
