.mainContainer {
	justify-content: center;
	align-items: center;
	text-align: center;
	display: flex;
	height: 100%;
}

.infoContainer {
	width: 40%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.buttonsContainer {
	width: 80%;
	align-self: center;
	display: flex;
	gap: 20px;
}

.img {
	width: 40%;
	display: flex;
	align-self: center;
}

@media (max-width: 1400px) {
	.infoContainer {
		width: 90%;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.img {
		width: 30%;
	}
}

@media (max-width: 750px) {
	.img {
		width: 50%;
	}
	.buttonsContainer {
		width: 100%;
		flex-direction: column;
	}
}
