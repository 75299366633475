.imgDetailsContainer {
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 5px;
}

.imgDetails {
	width: 111px;
	height: 91px;
	border-radius: 5px;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.headerContainer {
	background-color: #eaeaea;
	border-radius: 15px;
	padding: 8px 16px;
}

.contentContainer {
	background-color: #fbfbfb;
	border-radius: 15px;
	padding: 8px 16px;
}

.evidencesContainer {
	display: flex;
	flex-direction: column;
	gap: 10px;
	background-color: #fbfbfb;
	border-radius: 10px;
}

.addEvidenceText {
	display: flex;
	width: fit-content;
	cursor: pointer;
	color: var(--color-highlight-blue);
}

.tabsStyle {
	background: #ffffff !important;
	border-top-left-radius: 8px !important;
	border-top-right-radius: 8px !important;
	text-transform: none !important;
	margin-right: 10px !important;
	min-height: 50px !important;
}
