.summaryDetailContainer {
	padding: 25px;
	border: 1px solid #e4e5e5;
	display: flex;
	flex-direction: column;
	gap: 10px;
	font-size: 12px;
	border-radius: 9px;
	overflow: auto;
}

.summaryDetailContainer span {
	font-size: 16px;
	font-weight: bold;
	align-self: center;
}

.dividerSummary {
	height: 3px;
	background-color: #e3e3e3;
}

.iconSummaryContainer {
	display: flex;
	gap: 8px;
	align-items: center;
}

.iconSize {
	font-size: 18px;
}
