.actionContainer {
	display: flex;
	flex-direction: column;
	border: 4px dashed #9c9fb5;
	padding: 20px;
	width: 244px;
	height: 221px;
	text-align: center;
	justify-content: center;
	align-items: center;
	word-break: break-all;
	cursor: pointer;
}

.actionIconContainer {
	flex-direction: column;
	width: max-content;
	padding: 8px;
	border-radius: 5px;
	margin-bottom: 10px;
}

.actionTitle {
	font-size: 21px;
	color: #7f8297;
	font-weight: bold;
}

.actionSubtitle {
	font-size: 9px;
	color: #7f8297;
}

.actionIconContainer {
	color: var(--color-highlight-blue);
	padding: 20px;
	font-size: 18px;
}

.actionIcon {
	font-size: 3em !important;
}
