@import '../../config/colors.css';
.containerInput,
.centerElements {
	display: flex;
	align-items: flex-start !important;
	margin: 0px !important;
	width: 100%;
}
.labelContainerInput {
	margin: 0;
	font-size: 16px;
	font-weight: bold;
	line-height: 22px;
	width: max-content;
	text-overflow: clip;
	min-width: 100px;
	max-width: 150px;
	word-break: break-all;
}
.labelRequired {
	color: var(--color-primary-blue);
}
.textField {
	width: 100%;
	background-color: var(--color-6-grey);
}
.textFieldHelperText {
	margin: 0;
	border-radius: 5px;
	height: 50px;
	color: var(--color-primary-blue);
	background-color: #ffe2e5;
	border-color: var(--color-primary-blue);
	border-style: solid;
	border-width: 1px;
	display: flex;
	align-items: center;
	padding-left: 10px;
}

.centerElements {
	align-items: center !important;
	gap: 20px;
}
