.main-container-row {
	display: flex;
	gap: 20px;
}

.container-title {
	display: flex;
	width: 50%;
	align-items: center;
	gap: 5px;
	min-width: fit-content;
}

.circle {
	width: 7px;
	height: 7px;
	border-radius: 50%;
}

.color-popover-pending-supervision {
	background-color: var(--color-calendar-executing);
}

.color-popover-pending-validation {
	background-color: var(--color-yellow-main);
}

.color-popover-total-suspending {
	background-color: var(--color-calendar-executing);
}

.color-popover-total-accomplished {
	background-color: var(--color-calendar-accomplished);
}

.skeleton-container {
	width: 100%;
	height: 40px;
	position: relative;
	overflow: hidden;
	background-color: #f1f1f1;
}

.skeleton-container::before {
	content: '';
	position: absolute;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		90deg,
		transparent,
		rgba(255, 255, 255, 0.5),
		transparent
	);
	animation: loading 1.5s infinite;
}

@keyframes loading {
	0% {
		left: -100%;
	}
	100% {
		left: 100%;
	}
}
