.selectsContainer {
	display: flex;
	align-items: center;
	gap: 10px;
}

.selectsIcon {
	width: 36px !important;
	height: 36px !important;
	background-color: var(--color-highlight-blue);
	color: white;
	padding: 6px;
	border-radius: 50%;
}
