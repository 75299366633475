.tabsStyle {
	background: #ffffff !important;
	border-top-left-radius: 8px !important;
	border-top-right-radius: 8px !important;
	text-transform: none !important;
	margin-left: 10px !important;
	min-height: 50px !important;
}
.tabsStyleActive {
	background: #ffffff !important;
}
.tabsStyleEmpty {
	background: #ffbc40 !important;
	color: #ffffff !important;
}
.tabsStyleFull {
	background: #40ff89 !important;
	color: #ffffff !important;
}
