.paperRelative {
	position: absolute;
	background-color: #ffffff;
	right: 0.1%;
	top: 150%;
	z-index: 9;
}

.mainBox {
	padding: 20px;
	padding-left: 40px;
	padding-right: 40px;
	width: 450px;
	top: 0%;
	background-color: #ffffff;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	overflow-y: auto;
	max-height: min-content;
}

.dividerBox {
	padding-bottom: 10px;
	border-bottom: 1px solid #ccc;
}

.paddingInputs {
	padding-top: 10px;
}

.labelInput {
	font-size: 16px;
	width: 100%;
	padding: 0;
	margin: 0;
	padding: 7px 0;
	font: normal normal medium 15px/19px Inter;
	color: #111121;
}

.pd {
	padding-top: 20px !important;
	width: 100%;
	height: 100%;
}

.gridContainer {
	display: flex;
	flex-direction: column !important;
	justify-content: space-between;
}

.gridContainer > p {
	margin: auto;
}

.gridContainer > div {
	margin-top: auto;
}

.muiSelect {
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	padding: 8px;
	border: 1px solid #0000003b;
	border-radius: 4px;
	height: 41px;
	background-color: white;
}

.muiSelect > .option {
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
}

@media only screen and (max-width: 900px) {
	.mainBox {
		width: 80vw;
	}
}

@media (min-width: 900px) {
	.mainBox {
		top: 18%;
	}
}
@media (min-width: 1600px) {
	.mainBox {
		top: 15%;
	}
}
