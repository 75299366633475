@import '../../config/colors.css';

.solidButton {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	border-radius: 8px;
	border-color: white;
	border-width: 1px;
	border-style: solid;
	height: 45px;
	width: 100%;
	font-weight: bold;
	font-size: 16px;
	letter-spacing: 0.12px;
	background-color: var(--color-primary-main);
	cursor: pointer;
}
.solidButton:hover {
	background-color: var(--color-primary-dark);
	transition: 0.5s;
}
.solidButton:active {
	background-color: var(--color-primary-dark);
	transition: 0.5s;
}
.solidButton:disabled {
	background-color: #c7c6c5 !important;
	border-color: #c7c6c5 !important;
	color: #ffffff !important;
	cursor: default;
}
.iconComponent {
	margin-right: 10px;
}
.solidButton[data-size='sm'] {
	height: 35px;
	font-size: 14px;
}
.solidButton[data-style='outlined'] {
	background-color: transparent;
	border-color: 1px solid var(--color-primary-main);
	color: var(--color-primary-main);
}
.solidButton[data-style='outlined']:hover {
	background-color: rgba(21, 92, 252, 0.1);
	transition: 0.5s;
}

.solidButton[data-style='text'] {
	background-color: transparent;
	border: transparent;
	color: var(--color-primary-main);
}
.solidButton[data-style='text']:hover {
	background-color: rgba(21, 92, 252, 0.1);
	transition: 0.5s;
}
