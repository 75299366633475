.iconEditSupervision {
	font-size: 3vh !important;
	border-radius: 5px !important;
	box-shadow: 0px 3px 6px #00000029 !important;
	background: #0080f9 0% 0% no-repeat padding-box !important;
	cursor: pointer;
	color: white !important;
}

.infoContainer {
	display: flex;
	flex-direction: column;
	background-color: rgba(0, 128, 249, 0.04);
	gap: 10px;
	padding: 20px;
	border-radius: 17px;
}

.infoTitle {
	color: #5f5f5f;
	font-weight: bold;
}

.titleTable {
	color: #707070;
	font-size: 17px;
}
.tabsStyle {
	background: #ffffff !important;
	border-top-left-radius: 8px !important;
	border-top-right-radius: 8px !important;
	text-transform: none !important;
	margin-right: 10px !important;
	min-height: 50px !important;
}
