.boxContainer {
	position: relative;
	display: inline-flex;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	padding: 20px;
	box-shadow: 9px 10px 29px -13px rgba(0, 0, 0, 0.37);
}

.circularProgress {
	width: 100% !important;
	height: auto !important;
	color: var(--color-green-main) !important;
}

.boxLabel {
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

.valueLabel {
	display: flex;
	color: #0080f9;
	text-align: center;
	flex-direction: column;
}

.labelValue {
	font-size: 35px;
	font-weight: 700;
}
