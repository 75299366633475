.backgroundGrey {
	background-color: #f8f8f8 !important;
	height: 100% !important;
}

.subtitle {
	font-weight: 700;
	font-size: 12px;
}

.grey {
	color: var(--color-grey-main);
}
