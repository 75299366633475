#tabla {
	border-radius: 0px;
	box-shadow: unset;
}

.header {
	background: transparent;
	border-bottom: 1px solid #dddddd;
}
.header > tr > th {
	color: black;
	padding: 16px;
	font-weight: 600;
	font-size: 14px;
}

#tabla tr:nth-child(even) {
	background-color: var(--color-5-grey);
}
tbody tr td {
	padding: 16px;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 24px;
	color: #333333;
	border-bottom: 1px solid #dddddd;
	background-color: white;
}

.pagination > .MuiPaginationItem-previousNext {
	background-color: transparent !important;
}

.sticky {
	position: sticky;
	left: 0;
	z-index: 1;
}

.stickyHeader {
	position: sticky;
	left: 0;
	background: transparent;
	z-index: 1;
}

.scroll {
	filter: drop-shadow(8px 0px 8px rgba(0, 0, 0, 0.1));
}
