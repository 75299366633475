.selectContainer {
	display: flex;
	align-items: center;
	width: 100%;
}

.labelSelectContainer {
	width: 85%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.labelSelectContainerFullWidth {
	width: 100%;
}

.labelSelectContainerWithDecorations {
	width: 70%;
}
