.headerModal {
	display: flex;
	flex-direction: column;
}

.titleContainerModal {
	display: flex;
	align-items: center;
	text-align: center;
	margin: 0;
	width: 100%;
}

.h3Modal {
	margin: 0;
	line-height: 27px;
	font-size: 18px;
	width: 100%;
}

.closeIconModal {
	position: absolute;
	top: 0%;
	right: 0%;
}

.decorationLineModal {
	width: 70px;
	background-color: #a50018;
	border: 0px;
	height: 4px;
	margin-top: 5px;
	align-self: center;
}

.childrenContainerModal {
	text-align: center;
	margin-top: 0;
	font-size: 16px;
}

.footerModal {
	justify-content: space-around;
	padding: 20px;
	border: 1px solid #ddd;
	background-color: #f5f5f5;
	gap: 20px;
}
