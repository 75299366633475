/* Table */
.tableCustom {
	width: 100%;
	min-width: 650px;
	font-size: 14px;
	border-collapse: collapse;
}
.tableCustom > thead {
	text-align: left;
	font-weight: bold;
	user-select: none;
}
.tableCustom > tbody {
	text-align: left;
}
.tableCustom > thead > tr > th,
.tableCustom > tbody > tr > td {
	font-size: 14px;
	padding: 12px 15px;
	color: #161c24;
}
.tableCustom > thead > tr > th[data-sort='true'] {
	cursor: pointer;
}
.tableCustom > tbody > tr {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.tableCustom > thead > tr > th[data-text='acciones'] {
	background-color: #ffffff;
	position: sticky;
	right: 0;
	z-index: 2;
}
.tableCustom > tbody > tr > td[data-text='acciones'] {
	background-color: #ffffff;
	position: sticky;
	right: 0;
	z-index: 2;
}
.tableCustom > tbody > tr:last-of-type {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.tableCustom > thead > tr > th > input[type='checkbox'],
.tableCustom > tbody > tr > td > input[type='checkbox'] {
	background-color: #fff;
	margin: 0;
	color: currentColor;
	width: 1.2em;
	height: 1.2em;
	border: 0.15em solid currentColor;
	border-radius: 0.2em;
}
.tableCustom > thead > tr > th[data-checkbox='true'] {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 5px;
	align-items: center;
}
/* Footer */
.footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
}
/* Paginacion */
.pagination {
	display: inline-flex;
	align-items: center;
	margin: 20px 0px;
	font-size: 14px;
}
.limitPerPage > select,
.pagination > select {
	margin: 0px 10px;
	color: #000000;
	background: #f3f6f9;
	border: 1px solid #acb4be;
	border-radius: 5px;
	padding: 3px 5px;
	outline: none;
}
/* Limite de pagina */
.limitPerPage {
	margin: 0px 10px;
	font-size: 14px;
}

.limitPerPage > span {
	margin-right: 5px;
}
/* Buscador de la tabla */
.inputSearch {
	display: flex;
	width: 100%;
	max-width: 350px;
	height: 35px;
	align-items: center;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: rgba(176, 190, 197, 0.1);
	margin: 0px 10px;
}
.inputSearch > input {
	width: 100%;
	flex-grow: 1;
	border: transparent;
	height: 35px;
	border-radius: 10px;
	background-color: transparent;
	outline: none;
	color: gray;
}

@media (min-width: 621px) and (max-width: 960px) {
	.containerSearch {
		flex-direction: column;
		gap: 10px;
	}

	.inputSearch {
		margin: 0px;
		width: 100%;
		max-width: 500px;
	}
}

@media (max-width: 621px) {
	.containerSearch {
		flex-direction: column;
		gap: 10px;
	}

	.inputSearch {
		margin: 0px;
		width: 100%;
		max-width: 500px;
	}

	.buttonContainer {
		width: 100%;
	}

	.containerButtonsSearch {
		flex-direction: column;
		width: 100%;
	}
}
