.title {
	font-size: 14px;
	margin: 0;
	color: white;
}

.stats {
	color: white;
	font-size: 50px;
	font-weight: 600;
}

.iconContainer {
	display: flex;
	align-items: center;
	justify-content: center;
}
