.dragNDropIconContainer {
	display: flex;
	flex-direction: column;
	width: max-content;
	padding: 10px;
	border-radius: 5px;
	margin-bottom: 10px;
	background-color: rgba(16, 162, 67, 0.12);
	word-break: break-all;
	color: #10a243;
}

.badgeCustom {
	display: flex;
	background-color: var(--color-primary-main);
	position: absolute;
	top: 5px;
	right: -8px;
	border-radius: 50%;
	padding: 3px;
	cursor: pointer;
}
