.renderItem {
	width: 100%;
	display: flex;
	flex-direction: row;
}
.renderItem > div {
	width: 100%;
	min-width: 30rem;
	height: auto;
	margin-right: 2rem;
}

.renderItem label {
	min-width: 30px;
}

.renderTitle {
	margin: 0;
	white-space: nowrap;
	width: auto;
}
