.renderItem {
	width: 100%;
	display: grid;
	grid-auto-columns: minmax(15rem, 1fr);
	grid-auto-flow: column;
}

.renderTitle {
	margin: 0;
	/*min-width: 15rem;*/
	white-space: nowrap;
	width: auto;
}
