.sheetsContainer {
	padding: 25px;
	background-color: #f4f5f5;
	border-radius: 9px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	font-size: 14px;
}

.sheetsContainer span {
	padding: 10px;
	border-radius: 5px;
	cursor: pointer;
	overflow: hidden;
}
