.mainContainer {
	justify-content: center;
	align-items: center;
	text-align: center;
	display: flex;
	height: 100%;
	flex-direction: column;
	gap: 50px;
	min-height: 75vh;
}

.titleContainer {
	width: 100%;
	text-align: start;
}

.titleContainer span {
	font-size: 20px;
	font-weight: bold;
}

.infoContainer {
	width: 60%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.buttonsContainer {
	width: 80%;
	align-self: center;
	display: flex;
	gap: 20px;
}

.img {
	width: 30%;
	display: flex;
	align-self: center;
}

@media (max-width: 1400px) {
	.infoContainer {
		width: 80%;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.img {
		width: 40%;
	}
}

@media (max-width: 900px) {
	.img {
		width: 40%;
	}
}
