.dayContainer {
	display: flex;
	flex-direction: row;
	gap: 10px;
	margin-top: 10px;
}

.dayDivider {
	background-color: #6d34e0;
	width: 1%;
	height: auto;
	border: 0px;
	margin: 0px;
}

.dayLabels {
	display: flex;
	width: 99%;
	flex-direction: column;
	color: #818181;
}

.dayTitle {
	font-weight: 700;
	color: black;
}

.supervisionContainer {
	word-break: break-all;
}
