.mainContainerUploadFile {
	display: flex;
	gap: 16px;
}

.titleUploadFile {
	font-size: 20px;
	font-weight: bold;
}

.dividerUploadFile {
	height: 2px;
	background-color: #e3e3e3;
}

.dragNDropContainer {
	display: flex;
	justify-content: center;
}

.buttonUploadFile {
	width: 160px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	border-radius: 8px;
	border-color: white;
	border-width: 1px;
	border-style: solid;
	height: 45px;
	width: 100%;
	font-weight: bold;
	font-size: 16px;
	letter-spacing: 0.12px;
	background-color: var(--color-highlight-blue);
	cursor: pointer;
	padding: 20px;
}
