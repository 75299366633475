.renderItem {
	display: grid;
	grid-auto-columns: minmax(15rem, 1fr);
	grid-auto-flow: column;
}

.actionsContainer {
	display: flex;
	gap: 10px;
	align-items: center;
	margin-top: 5px;
}

.attributesContainer {
	padding: 8px 16px;
	background-color: #e2f1ff;
	border-radius: 20px;
	min-width: 50px;
	text-align: center;
	align-items: center;
	color: #0080f9;
	display: flex;
	gap: 10px;
}

.labelAttributes {
	color: #0080f9;
	cursor: pointer;
}

.iconAttribute {
	font-size: 20px;
	cursor: pointer;
}
