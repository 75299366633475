@import '../../../config/colors.css';

.subContainer {
	width: 100%;
	height: auto;
}
.mainContainerImgs {
	background-image: url('../../../assets/imgs/background-login.png');
	background-size: cover;
	height: 100vh;
}
.mainContainerForm {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 50px;
}
.subContainerForm {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
}
.subContainerFormInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 70%;
	height: 100%;
}
.title {
	font-weight: 800;
	font-size: 30px;
	color: var(--color-primary-main);
}
.containerTitle {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	height: auto;
	margin-bottom: 50px;
}
.imgLogo {
	width: 150px;
}
.containerForgotPass {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 20px;
	margin-bottom: 30px;
}
.labelForgotPass {
	color: #7e8094;
	text-decoration: none;
}
.linkError {
	color: var(--color-error-main);
}
.linkResendCode {
	cursor: pointer;
	text-decoration: underline;
}
@media (max-width: 900px) {
	.mainContainerImgs {
		display: none;
	}
	.subContainerFormInfo {
		width: 90%;
		height: auto;
	}
	.subContainerForm {
		padding: 0px;
		margin-top: 5px;
	}
	.mainContainerForm {
		padding: 10px;
	}
}
