.item {
	display: flex;
	align-items: center;
	box-sizing: border-box;
	width: auto;
	height: auto;
	padding: 10px 5px;
	margin-bottom: 10px;
	border-radius: 5px;
	user-select: none;
	background-color: white;
}

.labelRol {
	font-size: 10px;
}

.labelArea {
	font-size: 12px;
	margin-top: 0;
	word-wrap: break-word;
}

.titleItem {
	font-size: 18px;
	font-weight: 700;
	margin: 0;
}
