.backgroundGrey {
	background-color: #f8f8f8;
	max-height: 100px;
	overflow: hidden;
}

.iconCards {
	font-size: 4vh !important;
	padding: 6px;
	color: white;
	background-color: #744cb4;
	border-radius: 50%;
	margin-right: 20px;
}

.position {
	max-height: 100px;
}

.title {
	font-weight: 700;
	font-size: 16px;
	display: flex;
	align-items: center;
}

.subtitleCard {
	font-size: 12px;
	font-weight: 500;
}

.titleColorCard {
	text-align: left;
	position: relative;
	top: -20px;
}

.actions {
	justify-content: space-between !important;
}

.moreIcon {
	font-size: 3vh !important;
	background-color: #f6f8fa !important;
	border-radius: 50% !important;
	box-shadow: 9px 10px 29px -13px rgba(0, 0, 0, 0.37) !important;
	cursor: pointer;
	color: #a2a5b5;
}
