.labelStatus {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-inline: 10px;
	border-radius: 20px;
	height: 40px;
	font-size: 12px;
	font-weight: bold;
}
