.boardMainContainer {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	width: 100%;
	column-gap: 20px;
	overflow-x: scroll;
	overflow-y: hidden;
}

.boardMainContainer::-webkit-scrollbar {
	width: 5px;
	border-radius: 20px;
}

.boardColumn {
	min-width: 180px;
	text-align: center;
}

.dayLabel {
	font-size: 20px;
	color: #606368;
	position: relative;
}

.calendarContainer {
	display: flex;
	flex-direction: column;
}

.numberLabel {
	width: 40px;
	align-self: center;
	color: #878a8d;
	font-size: 18px;
	margin: 10px 0px 20px 0px;
	padding: 6px;
	border-radius: 50%;
}

.currentDay {
	background-color: #70b8fc;
	color: white;
}

.leftArrow,
.rightArrow {
	position: absolute;
	color: #62656a;
	cursor: pointer;
	top: 3px;
}

.leftArrow {
	left: 0%;
}

.rightArrow {
	right: 0%;
}
