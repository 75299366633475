.uploadPDF {
	width: 500px;
	height: 75px;
}

.cursorStyle {
	cursor: pointer;
}

.cardImage {
	width: 69px;
	height: 75px;
	border-radius: 10px;
	border: none;
	color: #0480f9;
	background-color: #eef7ff;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	gap: 2px;
}

.cardImagePicked {
	color: var(--color-green-main);
	background-color: var(--color-green-light);
}

.cardImage span {
	font-size: 11px;
}

.cardInformation {
	width: max-content;
	height: auto;
	border-radius: 10px;
	display: flex;
	align-items: center;
	border: 2px solid #eef7ff;
	gap: 10px;
	padding-left: 10px;
	background-color: transparent;
	color: #528295;
}

.cardInformation p {
	font-size: 14px;
	width: 250px;
}

.cardInformation svg {
	font-size: 20px;
	color: #70cff6;
}

@media (min-width: 1200px) and (max-width: 1390px) {
	.cardInformation p {
		font-size: 14px;
		width: 190px;
	}
}

@media (max-width: 470px) {
	.cardInformation p {
		width: 115px;
	}
	.uploadPDF {
		height: max-content;
	}
}
