.welcome {
	font-size: 22px;
	color: #282d35;
}
.mainContainer {
	justify-content: center;
	padding: 30px 0px 30px 0px;
	align-items: center;
	text-align: center;
	display: flex;
	height: 100%;
}

.infoContainer {
	width: 30%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.buttonsContainer {
	width: 80%;
	align-self: center;
	display: flex;
	gap: 20px;
}

@media (max-width: 1400px) {
	.infoContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}
