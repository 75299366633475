.renderItem {
	display: flex;
	gap: 30px;
	width: auto;
}

.labelTeam {
	font-size: 15px;
	color: #0080f9;
	cursor: pointer;
}

.containerScroll {
	display: flex;
	gap: 30px;
	width: auto;
}

/* width */
::-webkit-scrollbar {
	height: 6px;
	width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #cbcbcb;
	border-radius: 10px;
}
