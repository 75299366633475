.renderItem {
	width: 100%;
	display: grid;
	grid-auto-columns: minmax(15rem, 1fr);
	grid-auto-flow: column;
}

.renderItem label {
	min-width: 30px;
}

.renderTitle {
	margin: 0;
	/*min-width: 15rem;*/
	white-space: nowrap;
	width: auto;
}

@media(max-width: 1400px){
	.renderItem{
		width: 80% !important;
	}
}
@media(max-width: 1200px) {
	.renderItem {
		width: 60% !important;
	}
}