.dragFile {
	border: 4px dashed #9c9fb5;
	height: 400px;
	cursor: pointer;
	transition: all 0.3s;
}

.dragFileInformation {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	color: #7f8297;
	text-align: center;
}

.dragFileInformationSVG {
	width: 90px !important;
	height: 80px !important;
}

.dragOver {
	opacity: 0.5;
}
