.containerDay {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
	font-size: 14px;
}

.numberCalendar {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: #6db5f9;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}
