@import '../../config/colors.css';

.container {
	background-color: #ffffff;
	width: 100%;
	height: 400px;
}
.containerImage {
	width: 100%;
	height: 160px;
}
.imgContainer {
	width: 100%;
	height: 160px;
	object-fit: cover;
}
.containerInfo {
	padding: 20px;
}
.title {
	margin-top: 0px;
	font-weight: bold;
}
.textInfo {
	font-size: 14px;
}
.buttonSeeMore {
	cursor: pointer;
	text-transform: none !important;
	font-weight: bold;
	font-size: 16px;
	padding-inline: 0px;
	color: var(--color-secondary-main);
}
.buttonSeeMore:active {
	color: var(--color-secondary-dark);
}
.infoContainer {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden;
}
