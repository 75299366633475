.fc-dayGridDay-button,
.fc-dayGridWeek-button,
.fc-dayGridMonth-button,
.fc-multiMonthYear-button {
	background: #cbe5fb;
	border: transparent;
	--bs-btn-color: #0d6efd;
	margin: 0 7px 0 0;
}

.fc-listWeek-button {
	background: #cbe5fb;
	border: transparent;
	--bs-btn-color: #0d6efd;
}

.fc-prevYear-button,
.fc-prev-button,
.fc-next-button,
.fc-nextYear-button {
	background: rgb(210, 210, 210);
	border: white;
}

.bi-chevron-double-left,
.bi-chevron-left,
.bi-chevron-right,
.bi-chevron-double-right {
	color: rgb(65, 64, 64);
}

.fc-col-header,
.fc-daygrid-body,
.fc-scrollgrid-sync-table {
	width: 100% !important;
}
