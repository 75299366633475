.renderItem {
	display: flex;
	gap: 30px;
	width: auto;
	align-items: center;
}
/* 
.rowPersonal {
	display: flex;
	width: 100%;
	overflow: auto;
	align-items: center;
	gap: 30px;
} */

/* width */
::-webkit-scrollbar {
	height: 6px;
	width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #cbcbcb;
	border-radius: 10px;
}
