.breadcrumbs {
	text-decoration: none;
	font-size: 14px;
	color: gray;
}

.breadcrumbs > ol {
	margin-right: 20px;
}

.breadcrumbs > ol > li > a {
	text-decoration: none;
	font-size: 14px;
	color: gray;
}

.breadcrumbs > ol > li:last-child > a {
	color: #020221;
	font-weight: 600;
}

.MuiBreadcrumbs-separator {
	font-weight: 800;
	font-size: 30px;
}

.arrowFunction {
	display: flex;
	align-items: center;
	font-size: 14px;
	color: var(--color-primary-main);
	cursor: pointer;
	user-select: none;
}

.arrowFunction > span {
	margin-left: 5px;
}

.arrowReturn {
	width: 14px;
	height: 14px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--color-primary-main);
	border-radius: 50%;
	color: var(--color-primary-main);
}
