.verticalStepperRoot,
.verticalStepperRootCollapsable {
	display: grid;
	row-gap: 3rem;
	margin-top: 3rem;
}

.verticalStepperRootCollapsable {
	margin-top: 0;
}

.verticalStepperRootCollapsable > div {
	display: flex;
}

.verticalStepperRoot > div {
	display: flex;
	align-items: center;
}

.verticalStepperRoot div,
.verticalStepperRootCollapsable div {
	position: relative;
}

.verticalStepperRoot p,
.verticalStepperRootCollapsable p {
	margin: 0;
	font-weight: bold;
	font-size: 16px;
}

.steps {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.5s;
	z-index: 3;
}

.stepsOff {
	background: #a1a5b6;
}

.stepsOn {
	background: #10a243;
	z-index: 5;
}

.verticalStepperRoot > div:last-child,
.verticalStepperRootCollapsable > div:last-child {
	margin-bottom: 1rem;
}

.verticalStepperRoot:not(.independient).lines > div:not(:last-child)::after {
	content: '';
	width: 3px;
	min-height: 110%;
	height: auto;
	top: 45px;
	left: 21px;
	position: absolute;
	background-image: linear-gradient(#a1a5b6 33%, transparent 0%);
	background-position: right;
	background-size: 5px 8px;
	background-repeat: repeat-y;
	z-index: 1;
}

.verticalStepperRootCollapsable:not(.independient).lines > div:not(:last-child)::after {
	content: '';
	width: 3px;
	min-height: 110%;
	height: auto;
	top: 45px;
	left: 21px;
	position: absolute;
	background-image: linear-gradient(#a1a5b6 33%, transparent 0%);
	background-position: right;
	background-size: 5px 8px;
	background-repeat: repeat-y;
	z-index: 1;
}
