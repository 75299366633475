.container {
	display: flex;
	flex-direction: column;
}

.activeTasksContainer {
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
	color: #0080f9;
}

.label {
	font-weight: 600;
}

.activeTasks {
	font-size: 80px;
	font-weight: 800;
}

.pendingTasksContainer {
	display: flex;
	justify-content: space-between;
	color: var(--color-grey-main);
}
