.buttonLeft button,
.buttonRight button {
	min-width: 300px;
	margin: 0 20px;
}

@media (max-width: 620px) {
	.buttonLeft,
	.buttonRight {
		justify-content: center;
		margin: 10px auto !important;
	}
}
