.eachDocument {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.eachDocumentInformation {
	display: flex;
	align-items: center;
	flex-direction: column;
	align-items: flex-start;
	width: 40%;
}

.eachDocumentDetails {
	font-size: 12px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	color: black;
	font-size: 12px;
	padding: 0;
	gap: 40px;
	list-style: none;
	margin: 0;
	opacity: 0.5;
}

.eachDocumentDetails li {
	width: 100%;
	font-size: 12px;
	justify-content: space-between;
	color: #161818;
}

.eachDocumentActions {
	height: 70%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	justify-content: flex-end;
}

.actions {
	background-color: #e1e2e7;
	width: 33px;
	height: 33px;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.actions svg {
	color: #adafc2;
}

@media (max-width: 600px) {
	.eachDocumentActions {
		justify-content: flex-start;
	}
}
