.containerVerticalStepper {
	flex-wrap: nowrap;
	align-items: flex-start;
}

.verticalStepperSxElement {
	position: absolute !important;
	left: -5px;
	top: 0px;
	z-index: 1;
	height: 100%;
	background: #fff;
	min-width: 5rem;
}
