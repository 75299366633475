:root {
	--color-black-title: #020000;
	--color-primary-main: #a50018;
	--color-primary-dark: #8d0116;
	--color-primary-light: #e5edff;
	--color-secondary-main: #248efa;
	--color-secondary-dark: #1d7ad6;
	--color-grey-main: #cbcbcb;
	--color-grey-text: #5f5f5f;
	--color-green-main: #3abe21;
	--color-green-light: #d0f0c9;
	--color-green-dark: #079531;
	--color-yellow-main: #e35700;
	--color-yellow-light: #fae295;
	--color-yellow-dark: #e35700;
	--color-error-main: #dd0528;
	--color-error-light: #ffe2e5;
	--color-error-dark: #e21b1b;
	--color-info-main: #293990;
	--color-info-light: #eefafa;
	--color-info-dark: #283990;
	--color-highlight-blue: #0080f9;
	--color-text: #0080f9;
	--color-calendar-executing: #ffe203;
	--color-calendar-accomplished: #15b512;
}
