.containerPaper {
	padding: 25px;
}

.titleContainer {
	border-bottom: 1px solid #ccc;
}

.bold {
	font-weight: bold;
	font-size: 40px;
}

.cancelIcon {
	width: 40px;
	height: 40px;
	color: #f5f5f5;
	cursor: pointer;
}
