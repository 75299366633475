.title {
	font-weight: 700;
	font-size: 16px;
	display: flex;
	align-items: center;
}

.subtitle {
	font-weight: 700;
	font-size: 12px;
}

.blue {
	color: #0080f9;
	z-index: 0;
	cursor: pointer;
}

.grey {
	color: var(--color-grey-main);
}

.margin {
	margin-bottom: 50px;
}

.infoTaskContainer {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.circleTask {
	font-size: 10px !important;
}

.listTask {
	display: flex;
	gap: 8px;
	align-items: center;
}

.backgroundGrey {
	background-color: #f8f8f8 !important;
	height: 100% !important;
}

.iconExecution {
	font-size: 5vh !important;
	padding: 6px;
	color: white;
	background-color: #744cb4;
	border-radius: 50%;
	margin-right: 20px;
}
